// iot-sensor-app/src/components/EditSensorCard.js
import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Container, Typography,Grid2, Paper, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar} from '@mui/material';
import { FixedSizeList } from "react-window";
import SensorsIcon from '@mui/icons-material/Sensors';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

const RenameDialogContent = ({id, oldName, setName, handleEditName}) => {
  return (
    <>
    <DialogContent> {/* 添加底部间距 */}
      <DialogTitle style={{ marginTop: '0px', marginBottom: '16px', textAlign: 'center' }}> RENAME <br/> MAC: {id.toUpperCase()} <br/> Current Name : {oldName}</DialogTitle> {/* 添加文本居中对齐 */}
        <TextField
          label="Edit Name"
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
      <Button variant="contained" onClick={handleEditName}>Save</Button>
    </DialogActions>
    </>
  );
};

const DeleteDialogContent = ({id, oldName, handleDeleteSensor}) => {

  return (
    <>
    <DialogContent> {/* 添加底部间距 */}
      <DialogTitle style={{ marginTop: '0px', marginBottom: '16px', textAlign: 'center' }}> DELETE <br/> MAC: {id.toUpperCase()} <br/> Current Name : {oldName}</DialogTitle> {/* 添加文本居中对齐 */}
      <Typography style={{ textAlign: 'center' ,color: 'red'}}>Are you sure to delete this sensor?</Typography>
      </DialogContent>
      <DialogActions>
      <Button variant="contained" onClick={handleDeleteSensor}>Confirm</Button>
    </DialogActions>
    </>
  );
};

const Row = ({ data, style, setOpen, id, setId, setOldName, setDeleteOpen}) => {
    return (
      <div style={style}>
        <ListItem >
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', minWidth: '100%' }}> 
            <Grid2 container alignItems="center" spacing={1}>
              <Grid2 item >
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#009999' }}>
                    {data['type'] == 'sensor' && <SensorsIcon />}
                    {data['type'] == 'aqmesh' && <FmdGoodIcon />}
                  </Avatar>
                </ListItemIcon>
              </Grid2>
              <Grid2 item size="grow" paddingRight={3}>
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                  {data['deviceId'].toUpperCase()}
                </Typography>
              </Grid2>
              <Grid2 item size="grow">
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                  {data['name'] ? data['name'].toUpperCase() : 'null'}
                </Typography>
              </Grid2>
              <Typography variant="h6" style={{ flexGrow: 1 }}></Typography> {/* 添加 flexGrow 以推送内容到右侧 */}
              <Grid2 item xs={1} >
                <IconButton color="inherit" onClick={() => {setOpen(true); setId(id); setOldName(data['name'])}}> {/* 打开模态框 */}
                  <EditIcon />
                </IconButton>
              </Grid2 >
              
              <Grid2 item xs={1} >
                <IconButton color="inherit" disabled={data['type'] === 'aqmesh'} onClick={() => {setDeleteOpen(true); setId(id); setOldName(data['name'])}}> {/* 打开模态框 */}
                  <DeleteIcon />
                </IconButton>
              </Grid2 >
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};


const EditSensor = () => {
  const [sensorData, setSensorData] = useState({});
  const [searchName, setSearchName] = useState("");
  const [open, setOpen] = useState(false); // 添加状态管理
  const [oldName, setOldName] = useState("");
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const containerRef = useRef(null);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const [aqmeshData, setAQMeshData] = useState({});

  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/sensor/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();
        const SCDdata = sensorData.SCD ? JSON.parse(sensorData.SCD) : null;
        const SFAdata = sensorData.SFA ? JSON.parse(sensorData.SFA) : null;
        const SPSdata = sensorData.SPS ? JSON.parse(sensorData.SPS) : null;

        setSensorData(prevData => ({
          ...prevData,
          [sensorId]: { type: 'sensor', sps: SPSdata,scd: SCDdata, sfa: SFAdata, ...sensorData } // 更新 SPS 数据
        }));
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
    setIsLoaded(true);
  };

  const fetchAQMeshData = async () => {
    try {
      const response = await fetch('/api/aqmesh/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();
        const aqmeshData = sensorData.aqmesh ? JSON.parse(sensorData.aqmesh) : null;

        setSensorData(prevData => ({
          ...prevData,
          [sensorId]: { type: 'aqmesh', aqmesh: aqmeshData, ...sensorData } // 更新 SPS 数据
        }));
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
    setIsLoaded(true);
  };

  

  useEffect(() => {
    fetchLastRecord();
    fetchAQMeshData();
  }, [isLoaded]);

  const handleEditName = async () => {

    if(sensorData[id].type === 'aqmesh'){
      const response = await fetch('/api/aqmesh/edit_sensor_name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id, name: name }),
      });
      const result = await response.json();
    
      if (result.success){
        setSeverity("success");
        setSnackbarMessage(result.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(result.error);
      }
      setSnackbarOpen(true); // 打开 Snackbar
      setOpen(false); // 关闭模态框
      setIsLoaded(prev => !prev);
    };

    if(sensorData[id].type === 'sensor'){
    const response = await fetch('/api/sensor/edit_sensor_name', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id: id, name: name }),
      });
      const result = await response.json();
    
      if (result.success){
        setSeverity("success");
        setSnackbarMessage(result.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(result.error);
      }
      setSnackbarOpen(true); // 打开 Snackbar
      setOpen(false); // 关闭模态框
      setIsLoaded(prev => !prev);
    }
  }

  const handleDeleteSensor = async () => {

    if(sensorData[id].type === 'aqmesh'){
      const response = await fetch('/api/aqmesh/delete_sensor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id , name: name}),
      });
      const result = await response.json();
      if (result.success){
        setSeverity("success");
        setSnackbarMessage(result.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(result.error);
      }
      setSnackbarOpen(true); // 打开 Snackbar
      setDeleteOpen(false);
      setIsLoaded(prev => !prev);
    }
    if(sensorData[id].type === 'sensor'){
      const response = await fetch('/api/sensor/delete_sensor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: id , name: name}),
      });
      const result = await response.json();
      if (result.success){
        setSeverity("success");
        setSnackbarMessage(result.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(result.error);
      }
      setSnackbarOpen(true); // 打开 Snackbar
      setDeleteOpen(false);
      setIsLoaded(prev => !prev);
    }   
  }

  const filteredRecords = searchName 
    ? Object.entries(sensorData)
        .filter(([sensorId, data]) => (data.deviceId.includes(searchName)))
        .sort(([, a], [, b]) => a.name.localeCompare(b.name)).reverse() // 按名称排序
        : Object.entries(sensorData)
        .sort(([, a], [, b]) => {
          const nameA = a.name || ""; // 如果 a.name 为 null，则使用空字符串
          const nameB = b.name || ""; // 如果 b.name 为 null，则使用空字符串
          return nameA.localeCompare(nameB);
        }).reverse(); // 按名称排序

  return (
    <Container ref={containerRef}>
      <CssBaseline />
      <Box my={4} mx="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center" minWidth="60%" maxWidth="80%">
      <Typography variant="h4" style={{ marginBottom: '16px' }}>Edit Sensor</Typography> {/* 修改为 Dashboard 标题 */}
      <TextField
        label="Serach by MAC Address"
        value={searchName}
        onChange={(e) => setSearchName(e.target.value.toLowerCase())}
        fullWidth
        style={{ marginBottom: '16px' }} // 添加底部间距
        InputProps={{
          endAdornment: (
            searchName && (
            <InputAdornment position="end">
              <IconButton onClick={() => {setSearchName('')}}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
            )
          ),
        }}
      />

      <FixedSizeList
        height={375}
        itemSize={75} // 每个项的高度
        itemCount={filteredRecords.length} // 项的数量 + 1 for header
        width="100%" // 列表宽度
        onMouseDown={(e) => e.preventDefault()} // 禁用鼠标事件
        onMouseLeave={(e) => e.preventDefault()} // 禁用触摸事件
      >
        {({ index, style }) => {
          const [id, data] = filteredRecords[index]; // 获取传感器ID和数据
          return (
            <Row id={data['deviceId']} data={data} style={style} setOpen={() => setOpen(true)} key={id} setId={setId} setOldName={setOldName} setDeleteOpen={setDeleteOpen}/>
          );
        }}

      </FixedSizeList>
    </Box>
    <Dialog open={open} onClose={() => {setName(""); setOpen(false)}} fullWidth maxWidth="xs"> {/* 使用 Dialog 替代 Modal */}
        <RenameDialogContent id={id} oldName={oldName} setName={setName} handleEditName={handleEditName} />
    </Dialog>
    <Dialog open={deleteOpen} onClose={() => {setId(""); setDeleteOpen(false)}} fullWidth maxWidth="xs"> {/* 使用 Dialog 替代 Modal */}
        <DeleteDialogContent id={id} oldName={oldName} setName={setName} handleDeleteSensor={handleDeleteSensor} />
    </Dialog>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
    </Snackbar>
    </Container>
  );
};

export default EditSensor;