import React, { useEffect, useState, useRef } from 'react';
import { Slider,Button, Table, TableContainer, TableRow, TableCell, TableHead, TableBody, Stack, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import {Line} from 'react-chartjs-2';

const scdDatalist = [
  ["scd",0,1000],
  ["scd",1,1],
  ["scd",2,100],
  ["scd",3,100],
  ]

const sfaDatalist = [
  ["sfa",0,1],
  ["sfa",1,100],
  ["sfa",2,100],
]

const spsDatalist = [
  ["sps",0,100],
  ["sps",1,100],
  ["sps",2,100],
  ["sps",3,100],
  ["sps",4,100],
  ["sps",5,100],
  ["sps",6,100],
  ["sps",7,100],
  ["sps",8,100],
  ["sps",9,100],
]

const aqmeshParticlesDatalist = [
  ["particles",0],
  ["particles",1],
  ["particles",2],
  ["particles",3],
  ["particles",4],
  ["particles",5],
  ["particles",6],
  ["particles",7],
  ["particles",8],
  ["particles",9],
]
const aqmeshGasDatalist = [
  ["gas",0],
  ["gas",1],
]
  
const scdDataLabels = [
  "Battery [V]",
  "Co2 [ppm]",
  "Temperature [°C]",
  "Humidity [%]",
];

const sfaDataLabels = [
  "HCHO [ppb]",
  "Temperature [°C]",
  "Humidity [%]",
];

const spsDataLabels = [
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "NC 0.5 [#/cm³]",
  "NC 1.0 [#/cm³]",
  "NC 2.5 [#/cm³]",
  "NC 4.0 [#/cm³]",
  "NC 10.0 [#/cm³]",
  "Typical particle size [µm]"
];

const aqmeshParticlesDataLabels = [
  "Battery [V]",
  "Temperature [°C]",
  "Humidity [%]",
  "Pressure [mBar]",
  "PM 1.0 [µg/m³]",
  "PM 2.5 [µg/m³]",
  "PM 4.0 [µg/m³]",
  "PM 10.0 [µg/m³]",
  "Total Particle Counter [#/cm³]",
  "PM Total [µg/m³]",
];

const aqmeshGasDataLabels = [
  "Wind Speed [m/s]",
  "Wind Direction [°]",
];

const CusDataTable = ({ sensorId, startDate, endDate, filteredRecords, setSeverity, setSnackbarOpen, setSnackbarMessage , display}) => {
    const tableContainerRef = useRef(null); // 创建引用
    const [scdTableData, setScdTableData] = useState([]); // 初始化 chartData
    const [spsTableData, setSpsTableData] = useState([]); // 初始化 chartData
    const [sfaTableData, setSfaTableData] = useState([]); // 初始化 chartData
    const [aqmeshParticlesTableData, setAqmeshParticlesTableData] = useState([]); // 初始化 chartData
    const [aqmeshGasTableData, setAqmeshGasTableData] = useState([]); // 初始化 chartData
    const [timestamps, setTimestamps] = useState([]);
    const [index, setIndex] = useState(1);
    const [wssData,setWssData] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    const [isLineChart, setIsLineChart] = useState(false);

    const [numperPage, setNumperPage] = useState(window.innerWidth > 500 ? 10 : 3);

    const name = filteredRecords.map(record => (record[0] == sensorId) ? record[1]['name'] : null).filter(name => name !== null)[0];

    const exportToCSV = () => {
      const csvRows = [];

      const headers = ['TimeStamp',...scdDataLabels.map(label => label.split("[")[0]),...sfaDataLabels.map(label => label.split("[")[0]),...spsDataLabels.map(label => label.split("[")[0])];
      
      const newTimestamps = Object.keys(wssData['scd']);
      
      csvRows.push(headers.join(','));

      newTimestamps.forEach((timestamp) => {
        const row = [
          new Date(timestamp).toLocaleString('zh-CN', { hour12: false }),
          ...(wssData['scd'][timestamp] ? scdDatalist.map((_,index) => wssData['scd'][timestamp][index] / scdDatalist[index][2]) : []),
          ...(wssData['sfa'][timestamp] ? sfaDatalist.map((_,index) => wssData['sfa'][timestamp][index] / sfaDatalist[index][2]) : []),
          ...(wssData['sps'][timestamp] ? spsDatalist.map((_,index) => wssData['sps'][timestamp][index] / spsDatalist[index][2]) : []),
        ];
        csvRows.push(row.join(','));
      });

      const name = filteredRecords.map(record => (record[0] == sensorId) ? record[1]['name'] : null).filter(name => name !== null)[0];
      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', name + "_" + sensorId.toUpperCase() + "_" + startDate + "_" + endDate + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const exportAqmeshToCSV = () => {
      const csvRows = [];
      const headers = ['TimeStamp',...aqmeshParticlesDataLabels.map(label => label.split("[")[0]),...aqmeshGasDataLabels.map(label => label.split("[")[0])];
      
      const newTimestamps = Object.keys(wssData['particles']);
      
      csvRows.push(headers.join(','));

      newTimestamps.forEach((timestamp) => {
        const row = [
          new Date(timestamp).toLocaleString('zh-CN', { hour12: false }),
          ...(wssData['particles'][timestamp] ? aqmeshParticlesDatalist.map((_,index) => wssData['particles'][timestamp][aqmeshParticlesDatalist[index][1]]) : []),
          ...(wssData['gas'][timestamp] ? aqmeshGasDatalist.map((_,index) => wssData['gas'][timestamp][aqmeshGasDatalist[index][1]]) : []),
        ];
        csvRows.push(row.join(','));
      });
      const name = filteredRecords.map(record => (record[0] == sensorId) ? record[1]['name'] : null).filter(name => name !== null)[0];

      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', name + "_" + sensorId.toUpperCase() + "_" + startDate + "_" + endDate + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const fetchData = async () => {
      try {
        if(endDate === null || startDate === null){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Time range cannot be empty");
          return;
        }
        if(endDate < startDate){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("End time cannot be earlier than start time");
          return;
        }

        const adjustedEndDate = new Date(new Date(endDate).setHours(23, 59, 59)).getTime();
        const adjustedStartDate = new Date(new Date(startDate).setHours(0, 0, 0)).getTime();
        const url = "/api/sensor/history?repeator_id=" + sensorId + "&start_time=" + adjustedStartDate + "&end_time=" + adjustedEndDate;
        const response = await fetch(url);
        const data = await response.json();

        if(data.length === 0){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Over time range");
          return;
        }

        setIsLoaded(true);
        
        setWssData(data);
        setSeverity("success");
        setSnackbarOpen(true);
        setSnackbarMessage("Data fetched successfully");
        const newTimestamps = Object.keys(data['scd']).slice((index - 1) * numperPage, index * numperPage);
        setTimestamps(newTimestamps); // 追加时间戳
        setScdTableData(newTimestamps.map(timestamp => data['scd'][timestamp])); // 追加 scd 数据
        setSpsTableData(newTimestamps.map(timestamp => data['sps'][timestamp])); // 追加 sps 数据
        setSfaTableData(newTimestamps.map(timestamp => data['sfa'][timestamp])); // 追加 sfa 数据
      } catch (error) {
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Error fetching data");
          console.error('Error fetching chart data:', error);
      }
    };

    const fetchAqmeshData = async () => {
      try {
        if(endDate === null || startDate === null){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Time range cannot be empty");
          return;
        }
        if(endDate < startDate){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("End time cannot be earlier than start time");
          return;
        }

        const adjustedEndDate = new Date(new Date(endDate).setHours(23, 59, 59)).getTime();
        const adjustedStartDate = new Date(new Date(startDate).setHours(0, 0, 0)).getTime();
        const url = "/api/aqmesh/history?pod_serial_number=" + sensorId + "&start_time=" + adjustedStartDate + "&end_time=" + adjustedEndDate;
        const response = await fetch(url);
        const data = await response.json();

        if(data.length === 0){
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Over time range");
          return;
        }
        
        setWssData(data);
        
        setSeverity("success");
        setSnackbarOpen(true);
        setSnackbarMessage("Data fetched successfully");
        const newTimestamps = Object.keys(data['particles']).slice((index - 1) * numperPage, index * numperPage);
        setTimestamps(newTimestamps); // 追加时间戳
        setAqmeshParticlesTableData(newTimestamps.map(timestamp => data['particles'][timestamp]));
        setAqmeshGasTableData(newTimestamps.map(timestamp => data['gas'][timestamp]));
        setScdTableData([]);
        setSpsTableData([]);
        setSfaTableData([]);
        
      } catch (error) {
          setSeverity("error");
          setSnackbarOpen(true);
          setSnackbarMessage("Error fetching data");
          console.error('Error fetching chart data:', error);
      }
    };

    useEffect(() => {
      if (sensorId) {
        const type = filteredRecords.map(record => (record[0] == sensorId) ? record[1]['type'] : null).filter(type => type !== null)[0]
        if(type === 'aqmesh'){
          fetchAqmeshData();
        } else {
          fetchData();
        }
      }
    }, [display]);

    useEffect(() => {

      if (wssData.length !== 0) {
        const type = filteredRecords.map(record => (record[0] == sensorId) ? record[1]['type'] : null).filter(type => type !== null)[0]
        if(type === 'aqmesh'){
          console.log(Object.keys(wssData['particles']).length, (index - 1) * numperPage + 1 );
          const newTimestamps = Object.keys(wssData['particles']).slice((index - 1) * numperPage, (index * numperPage < Object.keys(wssData['particles']).length) ? index * numperPage : Object.keys(wssData['particles']).length);
          if (newTimestamps.length === 0){ return; }
          setTimestamps(newTimestamps);
          setAqmeshParticlesTableData(newTimestamps.map(timestamp => wssData['particles'][timestamp]));
          setAqmeshGasTableData(newTimestamps.map(timestamp => wssData['gas'][timestamp]));
        } else {
          const newTimestamps = Object.keys(wssData['scd']).slice((index - 1) * numperPage, (index * numperPage < Object.keys(wssData['scd']).length) ? index * numperPage : Object.keys(wssData['scd']).length);
          if (newTimestamps.length === 0){ return; }
          setTimestamps(newTimestamps);
          setScdTableData(newTimestamps.map(timestamp => wssData['scd'][timestamp]));
          setSpsTableData(newTimestamps.map(timestamp => wssData['sps'][timestamp]));
          setSfaTableData(newTimestamps.map(timestamp => wssData['sfa'][timestamp]));
          setAqmeshParticlesTableData([]);
          setAqmeshGasTableData([]);
        }
      }
    }, [index]);
      
    return (
      <Stack direction="column" spacing={2} paddingBottom={2} alignItems="center">
        <div style={{marginTop:'2px'}}/>
        <Typography>Name : {name}</Typography>

        {scdTableData.length === 0 && spsTableData.length === 0 && sfaTableData.length === 0 && aqmeshParticlesTableData.length === 0 && aqmeshGasTableData.length === 0 && (
          <Typography>{isLoaded ? "No data found" : "Loading..."}</Typography>
        )}

        {(scdTableData.length !== 0 && spsTableData.length !== 0 && sfaTableData.length !== 0 && (
        <TableContainer ref={tableContainerRef} style={{ paddingLeft:'10px',paddingRight:'10px',overflowY: 'auto',overflowX: 'auto',height:'325px'}}>
          <Table
          borderAxis="bothBetween"
          stripe="odd"
          hover>
          <TableBody >
            <TableRow style={{position:'sticky',top:'0px',backgroundColor:'#404040'}}>
            <TableCell key={0} sx={{ padding: 1 }} style={{ border: '1px solid #555555',minWidth: '110px'}}> TimeStamp </TableCell>
              {timestamps.map((timestamp, index, _array) => (
                <TableCell key={index} sx={{ padding: 1 }} style={{ border: '1px solid #555555' ,minWidth: '75px'}}>{new Date(_array[index]).toLocaleString('zh-CN', { hour12: false })}</TableCell>
              ))}
            </TableRow>
          </TableBody>
          <TableBody>
          {scdDataLabels.map((label, index) => (
            <TableRow key={index}>
              <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555',minWidth: '110px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
              {scdTableData.map((data, _index, _array) => (
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' ,minWidth: '75px'}}>{_array[_index][scdDatalist[index][1]] / scdDatalist[index][2]}</TableCell>
              ))}
            </TableRow >
          ))}
          </TableBody>
          <TableBody>
            {sfaDataLabels.map((label, index) => (
              <TableRow key={index + numperPage}>
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555', minWidth: '110px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
                {sfaTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' , minWidth: '75px'}}>{_array[_index] ? _array[_index][sfaDatalist[index][1]] / sfaDatalist[index][2]: 'null'}</TableCell>
                ))}
              </TableRow >
            ))}
          </TableBody>
          <TableBody>
            {spsDataLabels.map((label, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555', minWidth: '110px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
                {spsTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' , minWidth: '75px'}}>{_array[_index] ? _array[_index][spsDatalist[index][1]] / spsDatalist[index][2]: 'null'}</TableCell>
                ))}
              </TableRow >
            ))}
          </TableBody>
          </Table>
      </TableContainer>
      ))}

      {(aqmeshParticlesTableData.length !== 0 && aqmeshGasTableData.length !== 0 && (
        <TableContainer ref={tableContainerRef} style={{ paddingLeft:'5px',paddingRight:'5px', overflowY: 'auto',overflowX: 'auto',height:'325px'}}>
          <Table
          borderAxis="bothBetween"
          stripe="odd"
          hover>
          <TableBody>
            <TableRow style={{position:'sticky',top:'0px',backgroundColor:'#404040'}}>
            <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555',minWidth: '110px'}}> TimeStamp </TableCell>
              {timestamps.map((timestamp, index, _array) => (
                <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' ,minWidth: '70px'}} key={index}>{new Date(_array[index]).toLocaleString('zh-CN', { hour12: false })}</TableCell>
              ))}
            </TableRow>
          </TableBody>
          <TableBody>
          {aqmeshParticlesDataLabels.map((label, index) => (
            <TableRow key={index}>
              <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555',minWidth: '110px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
              {aqmeshParticlesTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' , minWidth: '70px'}}>{_array[_index][aqmeshParticlesDatalist[index][1]]}</TableCell>
                ))}
            </TableRow >
          ))}
          {aqmeshGasDataLabels.map((label, index) => (
            <TableRow key={index}>
              <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555',minWidth: '110px'}}>{label.split("[")[0]} {"[" + label.split("[")[1]}</TableCell>
              {aqmeshGasTableData.map((data, _index, _array) => (
                  <TableCell sx={{ padding: 1 }} style={{ border: '1px solid #555555' , minWidth: '70px'}}>{_array[_index][aqmeshGasDatalist[index][1]]}</TableCell>
                ))}
            </TableRow >
          ))}
          </TableBody>
          </Table>
      </TableContainer>
      ))}
      
      {(aqmeshParticlesTableData.length !== 0 && aqmeshGasTableData.length !== 0) && (
        <Stack direction="column" spacing={2} alignItems="center" marginTop={2} style={{paddingLeft:'20px',paddingRight:'20px'}}>
          <Slider value={index} onChange={(event, value) => setIndex(value)} min={1} max={Math.floor(Object.keys(wssData['particles']).length / numperPage) + 1}/>
          <Typography fontSize={14}>Rendered Records: {(index-1) * numperPage + 1 > Object.keys(wssData['particles']).length ? Object.keys(wssData['particles']).length - (numperPage - 1) : (index-1) * numperPage + 1} - {index * numperPage < Object.keys(wssData['particles']).length ? index * numperPage : Object.keys(wssData['particles']).length} of {Object.keys(wssData['particles']).length}</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography fontSize={window.innerWidth > 500 ? 14 : 12}>Start Time: {new Date(Object.keys(wssData['particles'])[0]).toLocaleString('zh-CN', { hour12: false })}</Typography>
            <Typography fontSize={window.innerWidth > 500 ? 14 : 12}>End Time: {new Date(Object.keys(wssData['particles'])[Object.keys(wssData['particles']).length - 1]).toLocaleString('zh-CN', { hour12: false })}</Typography>
          </Stack>
          <Button variant="contained" onClick={exportAqmeshToCSV} startIcon={<FileDownloadIcon />}>CSV</Button>
        </Stack>
      )}

      {(scdTableData.length !== 0 || spsTableData.length !== 0 || sfaTableData.length !== 0) && (
        <Stack direction="column" spacing={2} alignItems="center" style={{paddingLeft:'20px',paddingRight:'20px'}}>
          <Slider value={index} onChange={(event, value) => setIndex(value)} min={1} max={Math.floor(Object.keys(wssData['scd']).length / numperPage) + 1}/>
          <Typography fontSize={14}>Rendered Records: {(index-1) * numperPage + 1 > Object.keys(wssData['scd']).length ? Object.keys(wssData['scd']).length - (numperPage - 1) : (index-1) * numperPage + 1} - {index * numperPage < Object.keys(wssData['scd']).length ? index * numperPage : Object.keys(wssData['scd']).length} of {Object.keys(wssData['scd']).length}</Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography fontSize={window.innerWidth > 500 ? 14 : 12}>Start Time: {new Date(Object.keys(wssData['scd'])[0]).toLocaleString('zh-CN', { hour12: false })}</Typography>
            <Typography fontSize={window.innerWidth > 500 ? 14 : 12}>End Time: {new Date(Object.keys(wssData['scd'])[Object.keys(wssData['scd']).length - 1]).toLocaleString('zh-CN', { hour12: false })}</Typography>
          </Stack>
          <Button variant="contained" onClick={exportToCSV} startIcon={<FileDownloadIcon />}>CSV</Button>
        </Stack>
      )}
      
      </Stack>
    );
  };

export default CusDataTable;