import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

const Login = ({ onLogin }) => {

    const handleError = (errorResponse) => {
        console.log(errorResponse);
    }

    const handlecredentialResponse = async (credentialResponse) => {
        const response = await fetch('/google-auth/credential', {
            method: 'POST',
            body: JSON.stringify(credentialResponse),
        });

        const data = await response.json();
        if (data.status === "success") {
            window.location.href = '/';
        } else {
            console.log(data.message);
        }
    }
  
  return (
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    style={{ 
        backgroundImage: 'url(/login_bg.jpg)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center bottom',
        backgroundAttachment: 'fixed',
        position: 'relative' // 添加相对定位
    }}
    >
        <Box
            style={{
                position: 'absolute', // 添加绝对定位
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.67)', // 添加白色半透明覆盖层
                zIndex: 1 // 确保覆盖层在背景之上
            }}
        />
        <Box
            bgcolor="white"
            borderRadius="8px"
            boxShadow={3}
            p={4}
            width="380px"
            textAlign="center"
            style={{ zIndex: 2 }} // 确保内容在覆盖层之上
        >
            <img
                src="https://portali.hku.hk/cas-login-2022/hku-logo.png"
                alt="HKU Logo"
                style={{ width: '350px', marginBottom: '20px' }}
            />
            <Typography variant="h5" gutterBottom style={{ color: 'grey' }}>
                BEE IOT DASHBOARD
            </Typography>
            <Box display="flex" justifyContent="center" width="100%" marginTop="20px">
            <GoogleOAuthProvider clientId="558430998320-3p33igqbm0n6h0f91qpm0ulunv8uk437.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={handlecredentialResponse}
                    onError={handleError}
                    useOneTap={true}
                />
            </GoogleOAuthProvider>
            </Box>

        </Box>
    </Box>
  );
};

export default Login;