import React, { useRef, useEffect, useState } from 'react';
import { List,Avatar, Container, Typography, Grid2, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, ListItemButton, Collapse } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FixedSizeList } from "react-window";
import HistoryChart2 from '../components/HistoryChart2';
import { useNavigate, useLocation } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';



const Row = ({ data, className, style , onClick, lastUpdated }) => { // 添加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid2 container alignItems="center">
              <Grid2 item xs={2}>
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#009999' }}>
                    <FmdGoodIcon />
                  </Avatar>
                </ListItemIcon>
              </Grid2>
              <Grid2 item xs={10}>
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 125 ? 'inherit':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid2>
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};
  
function SensorCard({ sensorId, data, lastUpdated, handleChartClickOpen }) {
const [timeSinceUpdate, setTimeSinceUpdate] = useState(0);

useEffect(() => {
    const interval = setInterval(() => {
    setTimeSinceUpdate(Math.floor((Date.now() - lastUpdated) / 1000));
    }, 1000);

    return () => clearInterval(interval);
}, [lastUpdated]);

return (
    <Grid2 item xs={12} sm={12} md={6}>
    <Paper elevation={3} style={{ padding: '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        {data?.name ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography fontSize={14} variant="body1" noWrap>Name:</Typography>
          <Typography fontSize={14} variant="body1" noWrap>{data.name}</Typography>
        </div>
        ) : null}

        {data?.deviceId ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Pod Serial Number:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{data.deviceId}</Typography>
        </div>
        ) : null}
        {data?.location_number ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Location:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.location_number}</Typography>
        </div>
        ) : null}

        {data?.f?.battery_voltage ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Battery [V]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.battery_voltage}</Typography>
        </div>
        ) : null}

        {data?.f?.super_cap_voltage ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>SuperCap [V]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{data.f.super_cap_voltage}</Typography>
        </div>
        ) : null}

        {data?.f?.temperature_c ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Temperature [°C]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.temperature_c}</Typography>
        </div>
        ) : null}

        {data?.f?.humidity ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Humidity [%]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.humidity}</Typography>
        </div>
        ) : null}

        {data?.f?.pressure ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Pressure [hPa]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{data.f.pressure}</Typography>
        </div>
        ) : null}

        {data?.f?.pm1_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap >PM1.0 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.pm1_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.pm2_5_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM2.5 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.pm2_5_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.pm4_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM4.0 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.pm4_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.pm10_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM10 [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.pm10_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.pm_tpc_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Total Particle Counter [1/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.pm_tpc_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.pm_total_prescale ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>PM Total [µg/m³]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap pb={1}>{data.f.pm_total_prescale}</Typography>
        </div>
        ) : null}

        {data?.f?.aux1_prescaled ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Wind Speed [m/s]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.aux1_prescaled}</Typography>
        </div>
        ) : null}

        {data?.f?.aux2_prescaled ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography fontSize={14} variant="body1" noWrap>Wind Direction [degrees]:</Typography>
            <Typography fontSize={14} variant="body1" noWrap>{data.f.aux2_prescaled}</Typography>
        </div>
        ) : null}
        
        </div>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} pt={2} borderTop="1px solid #e0e0e0">
        <Typography fontSize={14} variant="body1" noWrap style={{ color: timeSinceUpdate > 3650 ? 'inherit' : 'inherit', visibility: timeSinceUpdate === 0 ? 'hidden' : 'visible' }}>
            Updated: {timeSinceUpdate} seconds ago
        </Typography>
        <Button variant="contained" onClick={handleChartClickOpen} style={{backgroundColor: '#999c91'}}>
            History
        </Button>
        </Box>
    </Paper>
    </Grid2>
);
}

const AQMesh = ({userData, notification}) => {
  const location = useLocation(); // 使用 useLocation 钩子
  const state = location.state;

  const [sensorData, setSensorData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [selectedSensorId, setSelectedSensorId] = useState(''); // 新增状态以跟踪选中的传感器ID
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [buttonState, setButtonState] = useState('online');
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [openChart, setopenChart] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (state?.notification){
        setSearchTerm(state?.notification ? state.notification : '');
        setButtonState(state?.notification ? 'offline' : 'offline');
        setDisplayedRecords(state?.notification ? Object.entries(sensorData).map(([sensorId]) => sensorId) : Object.entries(sensorData).map(([sensorId]) => sensorId));
    }
  }, [state?.notification]);
  
  const handleChartClickOpen = () => {
    setopenChart(true);
  };

  const handleChartClickClose = () => {
    setopenChart(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  console.log(sensorData);
  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/aqmesh/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {        
        const currentTime = new Date(sensorData.timestamp).getTime();
        if(sensorData.name){
          setSensorData(prevData => ({
            ...prevData,
            [sensorId]: {name:sensorData.name,...sensorData } // 更新 SPS 数据
        }));
        setLastUpdated(prevData => ({
            ...prevData,
            [sensorId]: currentTime
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchLastRecord();
    const interval = setInterval(fetchLastRecord, 60000); // 每30秒调用一次 fetchLastRecord
    return () => clearInterval(interval); // 清除定时器
  }, []);

 const filteredRecords = displayedRecords ? (Object.entries(sensorData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)})):Object.entries(sensorData).filter(([sensorId]) => Object.entries(lastUpdated).map(([sensorId,data]) => sensorId).includes(sensorId)).filter(([sensorId,data])=>{return data.name.includes(searchTerm)});
  return (
    <>
    {openChart && <HistoryChart2 sensorName={sensorData[selectedSensorId].name} sensorId={selectedSensorId} open={openChart} onClose={handleChartClickClose}/>}
      <CssBaseline />
      <Container ref={containerRef}>
        <Box paddingLeft={window.innerWidth > 500 ? 30 : 0} paddingRight={window.innerWidth > 500 ? 30 : 0}>
          <div style={{marginTop:'10px'}}/>
          <Paper elevation={3}>
            <List style={{marginTop:'0px'}}>
              <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemText primary="Search List" />
                {(selectedSensorId) && (open ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              <Collapse in={open}>
                <ListItem>           
                <TextField
                    style={{marginLeft:'10px',marginRight:'10px',marginTop:'10px'}}
                    variant="outlined"
                    placeholder="Search by Name"
                    value={searchTerm}
                    onChange={(e) => (
                        setButtonState(null),
                        setSearchTerm(e.target.value)
                    )}
                    fullWidth // 修改为 fullWidth
                    InputProps={{
                      endAdornment: (
                        searchTerm && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setSearchTerm('')}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                        )
                      ),
                    }} // 添加清空按钮
                  />
                </ListItem>
            <ListItem>
            <FixedSizeList
              height={375} // 设置列表高度
              itemSize={75} // 每个项的高度
              itemCount={filteredRecords.length} // 项的数量
              width="100%" // 列表宽度
            >
              {({ index, style }) => {
                const sensorId = filteredRecords[index][0]; // 获取传感器ID
                return (
                  <Row
                    style={style} 
                    onClick={() => {
                      setSelectedSensorId(sensorId);
                      handleDrawerOpen();
                      setOpen(false);
                    }} 
                    className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                    data = {filteredRecords[index]}
                    lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                  />
                );
              }}
            </FixedSizeList>
            </ListItem>
            </Collapse>
            </List>
            </Paper>
            <div style={{marginTop:'10px'}}/>
            {(selectedSensorId && !open) && (
            <Box>
              {selectedSensorId && (
                <SensorCard 
                  key={selectedSensorId} 
                  sensorId={selectedSensorId} 
                  data={sensorData[selectedSensorId]} 
                  lastUpdated={lastUpdated[selectedSensorId]} 
                  handleChartClickOpen={handleChartClickOpen}
                />
              )}
            </Box>
            )}
          </Box>
        </Container>
    </>
  );
};

export default AQMesh;