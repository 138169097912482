import React, { useState, useEffect, useRef } from 'react';
import { Switch,Avatar, Container, Typography, Grid, Paper, AppBar, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import { FixedSizeList } from "react-window";
import SensorsIcon from '@mui/icons-material/Sensors';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';

const SettingsRow = ({ id, data, style, fetchUpdateUserSettings}) => {
  return (
    <div style={style}>
      <ListItem >
        <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={1} lg={1}>
              <ListItemIcon>
                <Avatar variant="rounded" style={{ backgroundColor: '#3f51b5' }}>
                  <NotificationsIcon />
                </Avatar>
              </ListItemIcon>
            </Grid>

            <Grid item xs={8} lg={8}>
              <Typography 
                variant="h6" 
                style={{ color: 'primary', paddingLeft: '16px'}} // 修改颜色
              >
                {id}
              </Typography>
            </Grid>

            <Typography variant="h6" style={{ flexGrow: 1 }}></Typography> {/* 添加 flexGrow 以推送内容到右侧 */}
            <Grid item xs={1} lg={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Switch
              checked={data}
              onChange={(e) => {fetchUpdateUserSettings({field:id,value:e.target.checked})}}
            />
            </Grid>
          </Grid>
        </Paper>
      </ListItem>
    </div>
  );
};  

const UserSettings = () => {
  const containerRef = useRef(null);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [settings, setSettings] = useState({});

  const fetchUserSettings = async () => {
    const response = await fetch('/api/user/settings');
    const data = await response.json();
    if (data.error){
      window.location.href = '/';
      return;
    }else{ 
      setSettings(data);
    }
  }

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUpdateUserSettings = async ({field,value}) => {
    const response = await fetch('/api/user/update_settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ field,value }),
    });
    const data = await response.json();
    if (data.success){
      setSnackbarOpen(true);
      setSnackbarMessage(data.success);
      setSeverity("success");
    }else{
      setSnackbarOpen(true);
      setSnackbarMessage(data.error);
      setSeverity("error");
    }
    fetchUserSettings();
  }

  return (
    <Container ref={containerRef}>
      <CssBaseline />
      <Box my={4} mx="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center" maxWidth="60%">
      <Typography variant="h4" style={{ marginBottom: '16px' }}>User Settings</Typography> {/* 修改为 Dashboard 标题 */}
      
      <FixedSizeList
        height={375}
        itemSize={75} // 每个项的高度
        itemCount={Object.entries(settings).length} // 项的数量 + 1 for header
        width="100%" // 列表宽度
        onMouseDown={(e) => e.preventDefault()} // 禁用鼠标事件
        onMouseLeave={(e) => e.preventDefault()} // 禁用触摸事件
      >
        {({ index, style }) => {
          const [id, data] = Object.entries(settings)[index]; // 获取传感器ID和数据
          return (
            <SettingsRow key={index} id ={id} data={data} style={style} fetchUpdateUserSettings={fetchUpdateUserSettings}/>
          );
        }}

      </FixedSizeList>
    </Box>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
    </Snackbar>
    </Container>
  );
};

export default UserSettings;