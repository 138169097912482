import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Checkbox, FormControlLabel } from '@mui/material';

import { googleLogout } from '@react-oauth/google';

const InActive = ({ onLogin }) => {
    const fetchUserLogout = async () => {
        try {
            googleLogout();
            const response = await fetch('/api/user/logout');
            if(response.ok){
            window.location.href = '/login';
            }
        } catch (error) {
            console.error('Error fetching last record:', error);
        }
    };
  return (
    <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    style={{ 
        backgroundImage: 'url(https://portali.hku.hk/cas-login-2022/login_bg.jpg)', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center bottom',
        backgroundAttachment: 'fixed',
        position: 'relative' // 添加相对定位
    }}
    >
        <Box
            style={{
                position: 'absolute', // 添加绝对定位
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.67)', // 添加白色半透明覆盖层
                zIndex: 1 // 确保覆盖层在背景之上
            }}
        />
        <Box
            bgcolor="white"
            borderRadius="8px"
            boxShadow={3}
            p={4}
            width="380px"
            textAlign="center"
            style={{ zIndex: 2 }} // 确保内容在覆盖层之上
        >
            <img
                src="https://portali.hku.hk/cas-login-2022/hku-logo.png"
                alt="HKU Logo"
                style={{ width: '350px', marginBottom: '20px' }}
            />
            <Typography variant="h5" gutterBottom style={{ color: 'grey' }}>
                BEE IOT DASHBOARD
            </Typography>

            <Typography variant="h6" gutterBottom>
                Your account activation is under review.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Please contact the administrator.
            </Typography>
            <Button variant="contained" color="primary" onClick={fetchUserLogout}>Logout</Button>
        </Box>
    </Box>
  );
};

export default InActive;