import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // 更新导入
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from './pages/login-oauth'; // 修正文件名大小写
import InActive from './pages/InActive';
import Sensors from './pages/Sensors'; // 修正文件名大小写
import AQMesh from './pages/AQMesh';
import People from './pages/People';
import UserAdmin from './pages/UserAdmin';
import EditSensor from './pages/EditSensor';
import MainAppBar from './Layout/AppBar';
import Trends from './pages/Trends';
import UserSettings from './pages/UserSettings';

function detectDevice() { 
  if (navigator.userAgent.match(/Android/i) 
    || navigator.userAgent.match(/webOS/i) 
    || navigator.userAgent.match(/iPhone/i) 
    || navigator.userAgent.match(/iPad/i) 
    || navigator.userAgent.match(/iPod/i) 
    || navigator.userAgent.match(/BlackBerry/i) 
    || navigator.userAgent.match(/Windows Phone/i) 
  ) {
    return 'phone'; 
  } else { 
    return 'computer'; 
  } 
} 

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      paper: '#1e1e1e',
      default: '#404040',
    },
    primary: {
      main: '#999c91',
    },
  },
});

function App() {
  const [userData, setUserData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  //console.log(detectDevice())
  const fetchUserToken = async () => {
    try {
      const response = await fetch('/api/user/info');
      const data = await response.json();
      console.log(data)
      if (data.error){
        setUserData(null)
      }else{
        setUserData(prevData => ({
          ...prevData,
           email: data.email,
           name: data.name,
           picture: data.picture,
           is_admin: data.is_admin,
           is_active: data.is_active,
           notification: data.notification,
            ...prevData
        }))
      }
      setIsLoaded(true); // 确保这里的值是布尔值
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchUserToken();
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={darkTheme}>
        {isLoaded && (
          <Router>
            {(userData?.is_active) && (<MainAppBar userData={userData}/>)}
            <Routes>
              {(userData?.is_active) && (<Route path="/sensors" element={<Sensors userData={userData} />} />)}
              {(userData?.is_active) && (<Route path="/aqmesh" element={<AQMesh userData={userData} />} />)}
              {(userData?.is_active) && (<Route path="/people" element={<People userData={userData} />} />)}
              {(userData?.is_active) && (<Route path="/trends" element={<Trends />} />)}
              {(userData?.is_active) && (<Route path="/settings" element={<UserSettings />} />)}
              {(userData?.is_admin) && (<Route path="/edit" element={<EditSensor />} />)}
              {(userData?.is_admin) && (<Route path="/users" element={<UserAdmin />} />)}
              {(userData?.is_active) && (<Route path="/*" element={<Navigate to="/sensors" replace />}/>)}
              {(!userData?.email) && (<Route path="/login" element={<Login />} />)}
              {(!userData?.email) && (<Route path="/*" element={<Navigate to="/login" replace />} />)}
              {(!userData?.is_active && userData?.email) && (<Route path="/inactivate" element={<InActive />}/>)}
              {(!userData?.is_active && userData?.email) && (<Route path="/*" element={<Navigate to="/inactivate" replace/>}/>)}
            </Routes>
          </Router>
        )}
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;