import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Container, Typography, Grid2, Paper, CssBaseline, Box, TextField, ListItem, ListItemIcon, Snackbar } from '@mui/material';
import { FixedSizeList } from "react-window";
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

const Row = ({ data, style, fetchUpdateUser}) => {
    return (
      <div style={style}>
        <ListItem >
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid2 container alignItems="center" spacing={1}>
              <Grid2 item >
                <ListItemIcon>
                  <Avatar variant="rounded" style={{ backgroundColor: '#3f51b5' }}>
                    {data.is_admin ? <SupervisorAccountIcon /> : <PersonIcon />}
                  </Avatar>
                </ListItemIcon>
              </Grid2>

              <Grid2 item size="grow">
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}}
                >
                  {data.is_admin ? "Admin" : "User"}
                </Typography>
              </Grid2>

              <Grid2 item size="grow">
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                {data.name}
                </Typography>
              </Grid2>
              <Grid2 item size="grow">
                <Typography 
                  variant="h6" 
                  style={{ color: 'primary'}} // 修改颜色
                >
                  {data.email}
                </Typography>
              </Grid2>

              <Typography variant="h6" style={{ flexGrow: 1 }}></Typography> {/* 添加 flexGrow 以推送内容到右侧 */}
              {!data.is_admin && 
              <Grid2 item >
                <IconButton color="inherit" disabled={data.is_active} onClick={()=>{fetchUpdateUser(data.sub, data.email, !data.is_active)}}>
                  {data.is_active ? <CheckCircleOutlineIcon style={{color: 'grey'}}/> : <CheckCircleOutlineIcon style={{color: 'green'}}/>}
                </IconButton>
              </Grid2>}
              {!data.is_admin && 
              <Grid2 item >
                <IconButton color="inherit" disabled={!data.is_active} onClick={()=>{fetchUpdateUser(data.sub, data.email, !data.is_active)}}>
                  {!data.is_active ? <NotInterestedIcon style={{color: 'grey'}}/> : <NotInterestedIcon style={{color: 'red'}}/>}
                </IconButton>
              </Grid2>}

              {data.is_admin && 
              <Grid2 item >
                <IconButton color="inherit" disabled={data.is_admin}>
                <NotInterestedIcon style={{color: 'grey'}}/>
                </IconButton>
              </Grid2>}
              {data.is_admin && 
              <Grid2 item >
                <IconButton color="inherit" disabled={data.is_admin}>
                  <NotInterestedIcon style={{color: 'grey'}}/>
                </IconButton>
              </Grid2>}
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};


const UserAdmin = () => {
  const [userData, setUserData] = useState({});
  const [searchName, setSearchName] = useState("");
  const containerRef = useRef(null);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const fetchUserList = async () => {
    try {
      const response = await fetch('/api/user/list');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([userId, userData]) => {
        setUserData(prevData => ({
          ...prevData,
          [userId]: { ...userData } // 更新 SPS 数据
        }));
      });
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUpdateUser = async (id, email, is_active) => {
    try {
      const response = await fetch('/api/user/enable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, email , is_active}),
      })
      const data = await response.json();
      if (data.success){
        setSeverity("success");
        setSnackbarMessage(data.success);
      }else{
        setSeverity("error");
        setSnackbarMessage(response.error);
      }
      setSnackbarOpen(true);
      fetchUserList();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  }

  const filteredRecords = searchName 
    ? Object.entries(userData)
        .filter(([userId, data]) => (data.email.includes(searchName)))
    : Object.entries(userData); // 按名称排序

  return (
    <Container ref={containerRef}>
      <CssBaseline />
      <Box my={4} mx="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center" minWidth="60%" maxWidth="80%">
      <Typography variant="h4" style={{ marginBottom: '16px' }}>User Admin</Typography> {/* 修改为 Dashboard 标题 */}
      
      <TextField
        value={searchName}
        label="Serach by Email"
        onChange={(e) => setSearchName(e.target.value)}
        fullWidth
        style={{ marginBottom: '16px' }}
        inputProps={{
          endAdornment: (
            searchName && (
            <InputAdornment position="end">
              <IconButton onClick={() => setSearchName('')}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
            )
          ),
        }}

      />

      <FixedSizeList
        height={375}
        itemSize={75} // 每个项的高度
        itemCount={filteredRecords.length} // 项的数量 + 1 for header
        width="100%" // 列表宽度
        onMouseDown={(e) => e.preventDefault()} // 禁用鼠标事件
        onMouseLeave={(e) => e.preventDefault()} // 禁用触摸事件
      >
        {({ index, style }) => {
          const [id, data] = filteredRecords[index]; // 获取传感器ID和数据
          return (
            <Row id={data['deviceId']} data={data} style={style} fetchUpdateUser={fetchUpdateUser} key={id}/>
          );
        }}

      </FixedSizeList>
    </Box>
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={2000}
      onClose={() => setSnackbarOpen(false)}
    >
      <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
    </Snackbar>
    </Container>
  );
};

export default UserAdmin;