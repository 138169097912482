import React, { useRef, useEffect, useState } from 'react';
import { Tabs,Tab,Snackbar , Avatar, Container, Typography, Grid2, Paper, Toolbar, CssBaseline, Box, Button, TextField, ListItem, ListItemIcon, ListItemText, Drawer, List, Stack, Collapse, ListItemButton } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FixedSizeList } from "react-window";
import CusDataTable from '../components/CusDataTable';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear'; 
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const Row = ({ data, className, style , onClick, lastUpdated }) => { // ��加 lastUpdated 参数
    const timeSinceUpdate = Math.floor((Date.now() - lastUpdated) / 1000); // 计算更新时间
    return (
      <div style={style}>
        <ListItem button onClick={() => onClick()}>
          <Paper elevation={2} style={{ borderRadius: '8px', padding: '8px', width: '100%' }}>
            <Grid2 container alignItems="center">
              <Grid2 item>
                <ListItemIcon>
                  <Avatar variant="rounded" sx={{backgroundColor: '#009999'}}>
                    {data[1]?.type === 'sensor'&&  <SensorsIcon />}
                    {data[1]?.type === 'aqmesh'&&  <FmdGoodIcon />}
                  </Avatar>
                </ListItemIcon>
              </Grid2>
              <Grid2 item xs={10} size="small">
                <ListItemText
                  primary={
                    <Typography 
                      variant="h6" 
                      style={{ color: (className === 'selected' ? 'lightblue' : (timeSinceUpdate > 125 ? 'grey':'inherit') )}} // 修改颜色
                    >
                      {data[1].name}
                    </Typography>
                  }
                />
              </Grid2>
            </Grid2>
          </Paper>
        </ListItem>
      </div>
    );
};
  
const Trends = ({userData}) => {
  const [sensorData, setSensorData] = useState({});
  const [aqmeshData, setaqmeshData] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [displayedRecords, setDisplayedRecords] = useState(null);
  const [selectedSensorId, setSelectedSensorId] = useState(); // 新增状态以跟踪选中的感器ID
  const [buttonState, setButtonState] = useState("online");
  const [MenuOpen, setMenuOpen] = useState(false); // 新增状态以控制 Drawer
  const [drawerOpen, setDrawerOpen] = useState(false); // 新增状态以控制 Drawer
  const containerRef = useRef(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [display, setDisplay] = useState(false);

  const [severity, setSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [tabValue, setTabValue] = useState('INPUT');

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(event.target.innerText);
  };

  const fetchLastRecord_aqmesh = async () => {
    try {
      const response = await fetch('/api/aqmesh/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {        
        const currentTime = new Date(sensorData.timestamp).getTime();
        if(sensorData.name){
          setaqmeshData(prevData => ({
            ...prevData,
            [sensorId]: {type: 'aqmesh', name:sensorData.name,...sensorData } // 更新 SPS 数据
        }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  }

  
  const fetchLastRecord = async () => {
    try {
      const response = await fetch('/api/sensor/lastrecord');
      const data = await response.json();
      if (data.error){
        window.location.href = '/';
        return;
      }
      Object.entries(data).forEach(([sensorId, sensorData]) => {
        const currentTime = new Date(sensorData.timestamp).getTime();
        if(sensorData?.name){

          const SCDdata = sensorData.SCD ? JSON.parse(sensorData.SCD) : null
          const SPSdata = sensorData.SPS ? JSON.parse(sensorData.SPS) : null
          const SFAdata = sensorData.SFA ? JSON.parse(sensorData.SFA) : null

          setSensorData(prevData => ({
            ...prevData,
          [sensorId]: { type: 'sensor', name: sensorData.name, sps: SPSdata,scd: SCDdata, sfa: SFAdata} // 更新 SPS 数据
        }));
        }
      });
    } catch (error) {
      console.error('Error fetching last record:', error);
    }
  };

  useEffect(() => {
    fetchLastRecord();
    fetchLastRecord_aqmesh();
  }, []);

 const filteredRecords = (Object.entries(sensorData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)})).concat(Object.entries(aqmeshData).filter(([sensorId,data])=>{return data.name.includes(searchTerm)}))

  return (      
      <Container ref={containerRef}>
        <CssBaseline />
        <Box paddingLeft={window.innerWidth > 500 ? 0 : 0} paddingRight={window.innerWidth > 500 ? 0 : 0}>
        <div style={{marginTop:'10px'}}/>
        <Paper elevation={3}>
          <List style={{marginTop:'0px'}} alignItems={'center'} justifyContent={'center'}>
            <ListItemButton onClick={() => setOpen(!open)}>
              <ListItemText primary="Search List" />
              {(selectedSensorId && startDate && endDate) && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={open}>

            <ListItem >
              {window.innerWidth > 500 ? (
              <Stack direction="row" spacing={2} paddingLeft={'10px'} paddingRight={'10px'}>
              <TextField
              variant="outlined"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => (
                  setButtonState(null),
                  setSearchTerm(e.target.value)
              )}
              fullWidth // 修改为 fullWidth
              InputProps={{
                endAdornment: (
                  searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {setSearchTerm('')}}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                  )
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker fullWidth label="Start Date" InputLabelProps={{ shrink: true }} onChange={(e) => {setStartDate(e)}}/>
                <DatePicker fullWidth label="End Date" InputLabelProps={{ shrink: true }} onChange={(e) => {setEndDate(e)}}/>
            </LocalizationProvider>
            </Stack>
            ) : (

              <Stack direction="column" spacing={2} paddingLeft={'10px'} paddingRight={'10px'}>
            <TextField
              variant="outlined"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => (
                  setButtonState(null),
                  setSearchTerm(e.target.value)
              )}
              fullWidth // 修改为 fullWidth
              InputProps={{
                endAdornment: (
                  searchTerm && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => {setSearchTerm('')}}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                  )
                ),
              }}
            />
            <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker fullWidth label="Start Date" InputLabelProps={{ shrink: true }} onChange={(e) => setStartDate(e)} disableToolbar variant="inline"/>
                <DatePicker fullWidth label="End Date" InputLabelProps={{ shrink: true }} onChange={(e) => setEndDate(e)} disableToolbar variant="inline"/>
            </LocalizationProvider>
            </Stack>
            </Stack>
            )}
            </ListItem>
            
          
            <ListItem>
            <FixedSizeList
              height={375} // 设置列表高度
              itemSize={75} // 每个项的高度
              itemCount={filteredRecords.length} // 项的数量
              width="100%" // 列表宽度
            >
              {({ index, style }) => {
                const sensorId = filteredRecords[index][0]; // 获取传感器ID
                return (
                  <Row
                    style={style} 
                    onClick={() => {
                      setSelectedSensorId(sensorId);
                      handleDrawerOpen(); // 点击时打开 Drawer
                    }} 
                    className={selectedSensorId === sensorId ? 'selected' : ''} // 添加选中样式
                    data = {filteredRecords[index]}
                    lastUpdated={lastUpdated[sensorId]} // 传递 lastUpdated 参数
                  />
                );
                }}
              </FixedSizeList>
            </ListItem>
            <ListItem>
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={() => {
                  setDisplay(!display);
                  if(startDate && endDate){
                    if(selectedSensorId){
                      setTabValue('TABLE');
                      setOpen(false);
                    }else{
                      setSeverity('error');
                      setSnackbarOpen(true);
                      setSnackbarMessage('Please select sensor');
                    }
                  }else{
                    setSeverity('error');
                    setSnackbarOpen(true);
                    setSnackbarMessage('Please select date range');
                  }
                }}
              >
                GET TRENDS
              </Button>
            </ListItem>
            </Collapse>
            </List>
            </Paper>

            <div style={{marginTop:'10px'}}/>

            {!open && (
              <Paper elevation={3}>
              <Box >
                  <CusDataTable // 添加表格
                    sensorId={selectedSensorId}
                    startDate={startDate}
                    endDate={endDate}
                    filteredRecords={filteredRecords}
                    setSeverity={setSeverity}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarMessage={setSnackbarMessage}
                    display={display}
                  />
              </Box>
              </Paper>
            )}
          </Box>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert  variant="filled" onClose={() => setSnackbarOpen(false)} severity={severity} sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar>
      </Container>
  );
};

export default Trends;